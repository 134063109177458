import React from "react"
import styles from "../styles/imageHeader.module.scss"
import Img from "gatsby-image"

export default function ImageHeader({
  dateText,
  headerText,
  headerImageFluid,
}) {
  let headerDate = dateText ? (
    <h2 className={styles.headerDate}>{dateText}</h2>
  ) : null

  let headerTitle

  if (headerText && headerImageFluid) {
    let headerTitleClass = !headerDate
      ? styles.headerTitleNoImage
      : styles.headerTitle

    headerTitle = <h1 className={headerTitleClass}>{headerText}</h1>
  }

  let headerImage

  if (headerImageFluid) {
    headerImage = (
      <Img fluid={headerImageFluid} className={styles.blogPostHeaderImage} />
    )
  }
  return (
    <div className={styles.titleContainer}>
      {headerImage}
      {headerTitle}
      {headerDate}
    </div>
  )
}
