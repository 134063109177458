import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import kebabCase from "lodash.kebabcase"
import blogPostStyles from "../styles/blogPost.module.css"
// import SuggestedReading from "../components/suggestedReading"
import ImageHeader from "../components/ImageHeader"

function BlogPostTemplate(props) {
  const post = props.data.markdownRemark
  // const { previous, next } = props.pageContext
  const tags = post.frontmatter.tags || []

  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid

  let dateText = `${post.frontmatter.date} | Milos Popovic`

  console.log(post.html)

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        pagePath={props.location.pathname}
        image={featuredImgFluid.src}
      />
      <article className={blogPostStyles.blogPost}>
        <ImageHeader
          dateText={dateText}
          headerText={post.frontmatter.title}
          headerImageFluid={featuredImgFluid}
        />
        <section
          className={blogPostStyles.articleContent}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr />
        <div className={blogPostStyles.tags}>
          <ul>
            {tags.map(tag => (
              <Link key={kebabCase(tag)} to={`/tags/${kebabCase(tag)}`}>
                <li>{tag}</li>
              </Link>
            ))}
          </ul>
        </div>
      </article>
      {/* <SuggestedReading previous={previous} next={next} /> */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
